import { initAll } from '../../../node_modules/@id-sk/frontend/idsk/all';
import { handleMobileMenuIcons } from '../scripts/handleMobileMenuIcons';
import { nodeListForEach } from '../../../node_modules/@id-sk/frontend/govuk/common';
import { collapsibleFilter } from './collapsibleFilter';
import { modal } from './modal';
import { duplicate, handleDuplicateAutocomplete } from './duplicate';
import { autocomplete } from './autocomplete';

//IE NodeList forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

//IE .includes() polyfill
if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    }
    if (start === undefined) {
      start = 0;
    }
    return this.indexOf(search, start) !== -1;
  };
}

//IE .remove() polyfill
Element.prototype.remove = function () {
  this.parentElement.removeChild(this);
};
NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
  for (var i = this.length - 1; i >= 0; i--) {
    if (this[i] && this[i].parentElement) {
      this[i].parentElement.removeChild(this[i]);
    }
  }
};

window._components = window._components || {};

window._components = {
  autocomplete,
  duplicate,
  handleDuplicateAutocomplete
};

initAll();

//Handle mobile menu icons
handleMobileMenuIcons();

//Init collapsible filter
document.querySelectorAll('[data-collapsibleFilter]').forEach((el) => {
  collapsibleFilter(el).init();
});

// Initialize duplicate
document.querySelectorAll('[data-module="duplicate"]').forEach((el) => {
  if (!el.hasAttribute('data-duplicate-no-init')) {
    duplicate(el).init();
  }
});

// Initialize autocomplete
document.querySelectorAll('[data-autocomplete]').forEach((el) => {
  if (!el.hasAttribute('data-autocomplete-no-init')) {
    autocomplete(el).init();
  }
});

// Initialize modals
var $modals = document.querySelectorAll('[data-module="modal"]');
nodeListForEach($modals, function ($modal) {
  modal($modal).init();
});
