export function collapsibleFilter(module) {
  function init() {
    const openFilterButton = module.querySelector(
      '[data-collapsibleFilter-button]'
    );
    const filterContent = module.querySelector(
      '[data-collapsibleFilter-content]'
    );
    const backToResultsButton = module.querySelector(
      '[data-collapsibleFilter-back-to-results-button]'
    );
    const resetFilterButton = module.querySelector(
      '[data-collapsibleFilter-reset-filter-button]'
    );
    const showResultsButton = module.querySelector(
      '[data-collapsibleFilter-show-results-button]'
    );

    const body = document.body;
    const windowInnerWidth = window.innerWidth;

    if (
      openFilterButton &&
      filterContent &&
      backToResultsButton &&
      resetFilterButton &&
      showResultsButton
    ) {
      openFilterButton.addEventListener('click', () => {
        filterContent.classList.toggle('open');

        //Prevent scrolling when mobile menu is open
        if (windowInnerWidth <= 640) {
          body.classList.add('overflow-hidden');
        }
      });

      //Turns off filter content overlay, no filters are applied
      backToResultsButton.addEventListener('click', (e) => {
        e.preventDefault();

        openFilterButton.removeAttribute('open');
        filterContent.classList.remove('open');
        body.classList.remove('overflow-hidden');
      });

      //Apply filters and show results
      showResultsButton.addEventListener('click', (e) => {
        e.preventDefault();
        /*
          Logic behind aplying the filters to content
        */
        openFilterButton.removeAttribute('open');
        filterContent.classList.remove('open');
        body.classList.remove('overflow-hidden');
      });

      //Reset filter inputs
      resetFilterButton.addEventListener('click', (e) => {
        e.preventDefault();

        const inputs = filterContent.querySelectorAll('input');

        inputs.forEach((input) => {
          input.value = null;
        });

        const selects = filterContent.querySelectorAll('select');

        selects.forEach((select) => {
          select.selectedIndex = 0;
        });
      });
    } else {
      return;
    }
  }

  return { init };
}
