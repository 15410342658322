export const handleMobileMenuIcons = () => {
  const mobileMenuButtonOpen = document.querySelector(
    '.idsk-header-extended__menu-button'
  );
  const mobileMenuButtonClose = document.querySelector(
    '.idsk-header-extended__mobile-close'
  );

  //Hide hamburger icon on click so X icon can take it's place
  mobileMenuButtonOpen?.addEventListener('click', () => {
    mobileMenuButtonOpen.style.display = 'none';
    mobileMenuButtonClose.style.display = 'block';
  });

  //Show hamburger icon after clicking the X icon so hamburger icon can take it's place
  mobileMenuButtonClose?.addEventListener('click', () => {
    mobileMenuButtonClose.style.display = 'none';
    mobileMenuButtonOpen.style.display = 'block';
  });
};
